import { forwardRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AnyPlaybookType, PlaybookType } from 'app/types'

import { UseBooleanType, useQuery } from 'hooks'
import { isMobileDevice, isQG } from 'modules'

import { DesktopVideoPlayer } from './DesktopVideoPlayer'
import { MobileVideoPlayer } from './MobileVideoPlayer'

export const getPlayerStatus = (e: any) =>
    e.target.paused ? 'stopped' : 'playing'

export type Props = {
    tooltip?: string
    hideWatermark: boolean
    hoverControls?: JSX.Element
    showReactions?: UseBooleanType
    hoverState: UseBooleanType
    showGButtonAsIcon?: boolean
    showFinishStep?: boolean
    playbook: AnyPlaybookType
    playlist?: PlaybookType
    hideReactions?: boolean
    onPlay?: () => void
    onPause?: () => void
    onEnded?: () => void
    height?: string
    autoPlay?: boolean
    showPoster: boolean
    controls?: boolean
    playlistAutoPlay?: UseBooleanType
}

export type StatusType = 'loading' | 'playing' | 'stopped'

export type VideoPlayerProps = {
    status: StatusType
    animationUrl: string
    hideWatermark?: boolean
    poster: string
    setStatus: (_: StatusType) => void
    publicData?: {
        showCaptions: boolean
        hideButtonShareInEmbed?: boolean
    }
} & Props

const isMobile = isMobileDevice()

export const VideoPlayer = forwardRef<HTMLVideoElement | null, Props>(
    (props: any, ref: any) => {
        const [status, setStatus] = useState<StatusType>('loading')

        // we must use `useState` becuse we neeed to know and save initial width of the iframe
        // because when user open full screen mode of the video - width of the iframe
        // will be changed to the full screen width
        const [isSmallContainer] = useState(window.innerWidth <= 400)

        const query = new URLSearchParams(useLocation()?.search)
        const autoPlay = query.get('autoPlay') === 'true'

        const $publicFF = useQuery(
            `/c/v1/feature-flags-public?orgId=${props.playbook.uploadedByOrgId}`,
            {
                method: 'GET'
            }
        )

        if ($publicFF.isValidating) return null

        const animationUrl = isQG(props.playbook)
            ? props.playbook.previewUrl
            : props.playbook.gifUrl

        if (isMobile || isSmallContainer) {
            return (
                <MobileVideoPlayer
                    ref={ref}
                    {...props}
                    autoPlay={autoPlay}
                    publicData={$publicFF.data}
                    status={status}
                    poster={props.playbook.screenshotUrl}
                    animationUrl={animationUrl}
                    setStatus={setStatus}
                />
            )
        }

        return (
            <DesktopVideoPlayer
                ref={ref}
                {...props}
                autoPlay={autoPlay}
                publicData={$publicFF.data}
                status={status}
                hideWatermark={props.hideWatermark}
                poster={props.playbook.screenshotUrl}
                animationUrl={animationUrl}
                setStatus={setStatus}
            />
        )
    }
)
